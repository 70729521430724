import { createContext,  useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export const AiraloContext = createContext();

export default function AiraloProvider({children}) {

    const [token, setToken] = useState(null);
    const [globalpackages, setGlobalPackages] = useState([]);

    // Function to fetch the Airalo token
    const getAiraloToken = async () => {
        try {
            const response = await axios.post('http://127.0.0.1:5000/get-airalo-token');
            if (response.status === 200 && response.data.data) {
                const { access_token } = response.data.data;
                setToken(access_token);
                console.log("Airalo token retrieved successfully:", access_token);
                return access_token;  // Return the token so it can be used in fetchGlobalPackages
            } else {
                console.error("Failed to retrieve token:", response.data);
                return null;
            }
        } catch (error) {
            console.error("Error fetching Airalo token:", error);
            return null;
        }
    };

    // Function to fetch global packages, dependent on token availability
    const fetchGlobalPackages = useCallback(
        async () => {
            if (!token) {
                const tokenValue = await getAiraloToken();
                if (!tokenValue) {
                    console.error("Token not available for global packages request");
                    return;
                }
            }
    
            try {
                const response = await axios.get('http://127.0.0.1:5000/get-global-packages', {
                    headers: { Authorization: `Bearer ${token}` }
                });
    
                if (response.status === 200 && response.data) {
                    setGlobalPackages(response.data);
                    console.log("Global packages retrieved successfully:", response.data); 
                } else {
                    console.error("Failed to retrieve global packages:", response.data);
                }
            } catch (error) {
                console.error("Error fetching global packages:", error);
            }
        },
        [token, getAiraloToken]
    );
    



    const contextData = {
        getAiraloToken,
        token,
        globalpackages,
        fetchGlobalPackages
    }

  return (
    <AiraloContext.Provider value={contextData}>
      {children}
    </AiraloContext.Provider>
  )
}
