import React, { useState, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import footerimg from '../assets/Footer.jpg'
import facebook from '../assets/Facebook.svg'
import instagram from '../assets/Instagram.svg'
import tiktok from '../assets/tiktokwhite.svg'
import whatsapp from '../assets/Whatsapp.svg'
import twitter from  '../assets/twitterwhite.svg'
import linkedin from '../assets/linkedinwhite.svg'
import '../css/Footer.css'
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FilterContext } from '../context/FilterContext'
import GoogleTranslate from './GoogleTranslate'
export default function Footer() {

  const { language } = useContext(FilterContext);

  const [email, setEmail] = useState('');

  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://skystayserver-n8xf.onrender.com/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();
      if (response.ok) {
        toast.success(result.message, {
          position: "bottom-left",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
          closeButton: false,
        });
        setEmail('');
      } 
    } catch (error) {
      toast.error('Failed to subscribe', {
        position: "bottom-left",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
        closeButton: false,
      });
    }
  };


  return (
    <footer style={{
      // height: '300px',
      width: '100%',
      backgroundImage: `url(${footerimg})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }} 
    className="overflow-hidden"
    >
      <div className='px-7.5vw lg:px-[10px] py-16 flex flex-row justify-between text-white font-outfit md:flex-col sm:flex-col xsm:flex-col md:h-full sm:h-full xsm:h-full'>
        <div className='md:my-[10px] sm:my-[10px] xsm:my-[10px]'>
          <div className='w-fit'>
            <p className='text-2xl xsm:text-xl font-sentientbold font-bold h-11 content-center  hover:cursor-pointer'>SkyStay.Homes</p>
            <div className='h-1 w-full bg-blue-gradient m-1.5'/>
          </div>
          <ul>
            <NavLink to={'/properties'}>
              <li className='text-xs h-6 content-center leading-tight hover:underline hover:cursor-pointer'>Property Management</li>
            </NavLink>
            <NavLink to={'/portfolio'}>
              <li className='text-xs h-6 content-center leading-tight hover:underline hover:cursor-pointer'>Portfolio</li>
            </NavLink>
            <NavLink to={'/aboutus'}> 
              <li className='text-xs h-6 content-center leading-tight hover:underline hover:cursor-pointer'>About Us</li>
            </NavLink>
            <NavLink to={'/contactus'}>
              <li className='text-xs h-6 content-center leading-tight hover:underline hover:cursor-pointer'>Contact Us</li>
            </NavLink>
          </ul>
        </div>
        <div className='md:my-[10px] sm:my-[10px] xsm:my-[10px]'>
          <div className='w-fit'>
            <p className='text-xl font-sentientmedium font-light h-11 content-center'>Quick Links</p>
            <div className='h-1 w-full bg-blue-gradient m-1.5'/>
          </div>
          <ul>
            <NavLink to={'/faq'}>
              <li className='text-xs h-6 content-center leading-tight hover:underline hover:cursor-pointer'>FAQs</li>
            </NavLink>
            <NavLink to={'/blog'}>
              <li className='text-xs h-6 content-center leading-tight hover:underline hover:cursor-pointer'>Blog</li>
            </NavLink>
            <NavLink to={'/onboardform'}>
              <li className='text-xs h-6 content-center leading-tight hover:underline hover:cursor-pointer'>Onboard Form</li>
            </NavLink>
            <NavLink to={'/privacypolicy'}>
              <li className='text-xs h-6 content-center leading-tight hover:underline hover:cursor-pointer'>Privacy Policy</li>
            </NavLink>
            <NavLink to={'/termsandconditions'}>
              <li className='text-xs h-6 content-center leading-tight hover:underline hover:cursor-pointer'>Terms & Conditions</li>
            </NavLink>
          </ul>
        </div>
        <div className='md:my-[10px] sm:my-[10px] xsm:my-[10px]'>
          <div className='w-fit'>
            <p className='text-xl font-sentientmedium font-light h-11 content-center'>Contact Us</p>
            <div className='h-1 w-full bg-blue-gradient m-1.5'/>
          </div>
          <p className='text-xs h-6 content-center leading-tight'>Ikigai Westlands.
          Peponi Road, Westlands, Nairobi, Kenya</p>
          <p className='text-xs h-6 content-center leading-tight'>Email: bookme@skystay.homes</p>
          <p className='text-xs h-6 content-center leading-tight'>Phone: +254 743 501162</p>
          <div className='flex flex-row w-full justify-between my-2.5 md:w-2/6 sm:w-2/4 xsm:w-2/4'>
            <a href='https://www.facebook.com/share/U2yjnyzw9LeaAvs5/?mibextid=qi2Omg' target='_blank'><img className='cursor-pointer mx-2' src={facebook} alt='facebook'/></a>
            <a href='https://www.instagram.com/skystayhomes?igsh=bHNxaGV4YzM5bnFv' target='_blank'><img className='cursor-pointer mx-2' src={instagram} alt='instagram'/></a>
            <a href='https://vm.tiktok.com/ZMhJ9XY14/' target='_blank'><img className='cursor-pointer mx-2' src={tiktok} alt='whatsapp'/></a>
            <a href='https://x.com/SkyStayHomes?t=HM_Sn6GHk4YaBqA9Cbv7Gw&s=09' target='_blank'><img className='cursor-pointer mx-2' src={twitter} alt='twitter'/></a>
            <a href='https://www.linkedin.com/company/skystay/' target='_blank'><img className='cursor-pointer mx-2' src={linkedin} alt='linkedin'/></a>
          </div>
          {/* <div>
            <GoogleTranslate language={language} />
          </div> */}
        </div>
        {/* Newsletter section */}
        <div className='flex flex-col h-44 border-2 p-2.5 rounded md:mt-5 sm:mt-5 xsm:mt-5 md:max-w-[298px] sm:max-w-2/6 xsm:w-full'>
          <p className='text-sm font-medium h-11 content-center '>Newsletter</p>
          <p className='text-xs h-6 content-center my-2.5 xsm:text-[10px]'>Subscribe to our newsletter for latest special offers!</p>
          <input
            type='email'
            placeholder='Email'
            value={email} onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={ handleSubscribe}>Subscribe</button>
        </div>
      </div>
    </footer>
  )
}
