import React, {useState, useEffect, useContext} from 'react'
import { GoogleLogin } from '@react-oauth/google';
import 'react-toastify/dist/ReactToastify.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import { NavLink, useNavigate  } from 'react-router-dom';
import { Button } from './ui/button';
// import google from '../assets/google.svg'
import hamburger from '../assets/hamburger.svg'
import cross from '../assets/cross.svg'
import profimage from "../assets/mainprof.svg"
import smartphone from '../assets/smarticon.svg'
import '../css/Navbar.css'
import '../css/Signup.css'
import '../css/App.css';

// import ContactsMini from './ContactsMini';
import FlagDropdown from './FlagDropdown';
import { UserContext } from '../context/UserContext';

export default function Navbar() {

    const { user, loginUser, logoutUser, handleGoogleSuccess}= useContext(UserContext)

    const [menuOpen, setMenuOpen] = useState(false);
    const [showLoginCard, setShowLoginCard] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const navigate = useNavigate();
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        // Stash the event so it can be triggered later.
        setDeferredPrompt(e);
        });
    }, []);

    

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const rememberedEmail = localStorage.getItem('rememberedEmail');
        if (rememberedEmail) {
            setEmail(rememberedEmail);
            setRememberMe(true);
        }
    }, []);

    const toggleLoginCard = () => {
        setShowLoginCard(!showLoginCard);
      };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLogin = async () => {
        const userdata = { email, password };
        await loginUser(userdata);
        if (rememberMe) {
            localStorage.setItem('rememberedEmail', email);
        } else {
            localStorage.removeItem('rememberedEmail');
        }
        setShowLoginCard(false);
    };

    const handleLogout = () => {
        logoutUser();
        navigate('/');
    };

    const handleInstallClick = () => {
        if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
            } else {
            console.log('User dismissed the A2HS prompt');
            }
            setDeferredPrompt(null);
        });
        }
    };



  return (
    <div >
        <nav className={`w-100% px-7.5vw bg-blue xl:px-[10px] lg:px-[5px] md:px-5 sm:px-5 xsm:px-[15px] font-outfit flex flex-row justify-between h-16 text-white relative ${isScrolled ? 'scrolled' : ''}`}>
            <div className="text-2xl xsm:text-xl font-sentientbold font-bold h-16 content-center cursor-pointer">
                <NavLink to="/">SkyStay.Homes</NavLink>
            </div>
            {/* <div className="flex z-[999] items-center justify-center">
                <FlagDropdown />
            </div> */}
            {deferredPrompt && (
            <div className='text-sm content-center cursor-pointer md:hidden sm:hidden xsm:hidden'  onClick={handleInstallClick}>Install App</div>
            )}
            <ul className='text-sm flex flex-row h-16 items-center lg:text-[10px] md:text-xs xl:text-xs md:hidden sm:hidden xsm:hidden'>
                <NavLink to="/home"><li className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 rounded xl:m-1 xl:p-1 lg:p-1 lg:m-1 hover:bg-white hover:text-blue hover:rounded '>Home</li></NavLink>
                <NavLink to="/portfolio"><li className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 rounded xl:m-1 xl:p-1 lg:p-1 lg:m-1 hover:bg-white hover:text-blue hover:rounded '>Portfolio</li></NavLink>
                <NavLink to="/properties"><li className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 rounded xl:m-1 xl:p-1 lg:p-1 lg:m-1 hover:bg-white hover:text-blue hover:rounded'>Property Management</li></NavLink>
                <NavLink to="/aboutus"><li className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 rounded xl:m-1 xl:p-1 lg:p-1 lg:m-1 hover:bg-white hover:text-blue hover:rounded'>About Us</li></NavLink>
                <NavLink to="/contactus"><li className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 rounded xl:m-1 xl:p-1 lg:p-1 lg:m-1 hover:bg-white hover:text-blue hover:rounded'> Contact Us</li></NavLink>
                <NavLink to="/blog"><li className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 rounded xl:m-1 xl:p-1 lg:p-1 lg:m-1 hover:bg-white hover:text-blue hover:rounded'>Blog</li></NavLink>
            </ul>
            <div className='text-sm flex flex-row h-16 items-center justify-between lg:text-[10px] md:text-xs'>
                {/* <ContactsMini/> */}
                <div className='flex flex-row md:hidden sm:hidden xsm:hidden'>
                    <p className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 hover:underline'><NavLink to="/">Property Owners</NavLink></p>
                    {user && (
                        <p className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 hover:underline'>
                            <NavLink to='/dashboard'>My Dashboard</NavLink>
                        </p>
                    )}
                </div>
                <div className='flex flex-row items-center md:hidden sm:hidden xsm:hidden'>
                {user ? (
                    <>
                        {user.prof_img ? (
                            <img src={user.prof_img} style={{width: '30px', height: '30px', borderRadius: '50%', objectFit: 'cover', backgroundColor: 'white', padding: '2px'}}/>
                        ) : (
                            <img src={profimage} style={{width: '30px', height: '30px', borderRadius: '50%', objectFit: 'cover', backgroundColor: 'white', padding: '2px'}}/>
                        )}
                        <Button variant="nav" onClick={handleLogout}>Logout</Button>
                    </>
                ) : (
                    <>
                        <NavLink to="/signup"><Button to='/signup' variant="nav">Register</Button></NavLink>
                        <Button variant="nav" onClick={toggleLoginCard}>Log In</Button>
                    </>
                )}
                </div>
                <img className='h-4 cursor-pointer 2xl:hidden xl:hidden lg:hidden  ' src={hamburger} alt='menu' onClick={toggleMenu}/>
            </div>
            {menuOpen && (
                <div className={`menu ${menuOpen ? 'open' : ''} ${isScrolled ? 'scrolled' : ''}`}>
                    <div className='w-full flex justify-end p-4'>
                        <img className='h-4 cursor-pointer' src={cross} alt='cross' onClick={toggleMenu}/>
                    </div>
                    {deferredPrompt && (
                        <div className='text-xs w-fit my-[10px] p-[5px] rounded bg-blue text-white content-center cursor-pointer border-solid border-[1px] border-blue font-outfit flex justify-center items-center  2xl:hidden xl:hidden lg:hidden'  onClick={handleInstallClick}><img className='w-[20px] h-[20px] mr-[5px]' src={smartphone}/>Install App</div>
                    )}
                    <ul className='text-sm flex flex-col justify-start  xsm:text-xs '>
                        <NavLink to="/home"><li className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 rounded hover:bg-white hover:text-blue hover:rounded'>Home</li></NavLink>
                        <NavLink to="/portfolio"><li className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 rounded hover:bg-white hover:text-blue hover:rounded'>Portfolio</li></NavLink>
                        {/* <NavLink to="/flights"><li className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 rounded hover:bg-white hover:text-blue hover:rounded'>Flights</li></NavLink> */}
                        <NavLink to="/properties"><li className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 rounded hover:bg-white hover:text-blue hover:rounded'>Property Management</li></NavLink>
                        <NavLink to="/aboutus"><li className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 rounded hover:bg-white hover:text-blue hover:rounded'>About Us</li></NavLink>
                        <NavLink to="/contactus"><li className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 rounded hover:bg-white hover:text-blue hover:rounded'>Contact Us</li></NavLink>
                        <NavLink to="/blog"><li className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 rounded hover:bg-white hover:text-blue hover:rounded'>Blog</li></NavLink>
                        <NavLink to="/"><li className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 hover:underline'>Property Owners</li></NavLink>
                        {user && (
                            <NavLink to='/dashboard'><li className='m-2 p-2 cursor-pointer h-8 flex items-center transition duration-300 hover:underline'>My Dashboard</li></NavLink>
                            
                        )}
                    </ul>
                    <div className=' flex flex-row items-center justify-between xsm:text-xs'> 
                    {user ? (
                        <>
                            {user.prof_img ? (
                                <img src={user.prof_img} style={{width: '30px', height: '30px', borderRadius: '50%', objectFit: 'cover', backgroundColor: 'white', padding: '2px'}}/>
                            ) : (
                                <img src={profimage} style={{width: '30px', height: '30px', borderRadius: '50%', objectFit: 'cover', backgroundColor: 'white', padding: '2px'}}/>
                            )}
                            <Button variant="nav" onClick={handleLogout}>Logout</Button>
                        </>
                    ) : (
                        <>
                            <NavLink to="/signup"><Button to='/signup' variant="nav">Register</Button></NavLink>
                            <Button variant="nav" onClick={toggleLoginCard}>Log In</Button>
                        </>
                    )}
                    </div>
                </div>
            )}
        </nav>
        {/* Login Tab */}
        {showLoginCard && (
            <div className='login fixed top-[0px] py-[60px] w-full m-auto flex bg-black bg-opacity-40 items-start justify-center px-[7.5vw] lg:px-[10px] xsm:px-[0px]  font-outfit'>
                <div className='w-[340px] mx-auto bg-white h-fit p-[20px] my-10 rounded-lg flex flex-col  justify-center border-[1px] border-solid border-slate-300'>
                    <p className='text-xs underline text-blue self-end cursor-pointer' onClick={toggleLoginCard}>Close</p>
                    <p className='text-center'>Welcome to SkyStay.Homes</p>
                    <div className='mt-4 w-full flex flex-col '>
                        <label className='text-xs'>Email</label>
                        <input
                            className='input2'
                            type='email'
                            placeholder='Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className='mt-4 w-full flex flex-col '>
                        <label className='text-xs'>Password</label>
                        <input
                            className='input2'
                            type='password'
                            placeholder='Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className='mt-4 w-full flex flex-row items-center'>
                        <input
                            type='checkbox'
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                        />
                        <label className='ml-2 text-xs'>Remember Me</label>
                    </div>
                    <button className='signup bg-blue text-sm' onClick={handleLogin}>Login</button>
                    <p className='my-4 text-sm self-center'>or</p>
                    <div className='w-fit mx-auto'>
                        <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onError={() => {
                            console.log('Login Failed');
                            }}
                        />
                    </div>
                    <NavLink to='/resetpassword'>
                        <p className='text-center text-xs underline my-4 cursor-pointer' onClick={toggleLoginCard}>Forgot Password</p>
                    </NavLink>
                    <NavLink to='/signup'>
                        <p className='self-center text-xs underline my-4 cursor-pointer' onClick={toggleLoginCard}>Dont have an account Sign Up?</p>
                    </NavLink>
                </div>
            </div>  
        )}
    </div>
  )
}
